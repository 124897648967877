:root {
  --smallerSpace: 60px;
}
@media (max-width: 1197px) {
  :root {
    --smallerSpace: 40px;
  }
}
:root {
  --deskWidth: 1406px;
  --deskSpace: 80px;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
@media (max-width: 1197px) {
  :root {
    --deskSpace: 50px;
  }
}
@media (max-width: 767px) {
  :root {
    --deskSpace: 36px;
  }
}
:root {
  --spacePart: 20px;
}
@media (max-width: 1197px) {
  :root {
    --spacePart: 10px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 10px;
  }
}
:root {
  --spaceUnit: 40px;
}
@media (max-width: 1197px) {
  :root {
    --spaceUnit: 30px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 20px;
  }
}
:root {
  --spaceTotal: 60px;
}
@media (max-width: 1197px) {
  :root {
    --spaceTotal: 40px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 30px;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #e5005a;
  font-size: 19px;
  line-height: 1.47368421;
  letter-spacing: 0.025em;
  font-weight: 700;
  cursor: pointer;
  color: #fff;
  padding: 11px 40px;
  -webkit-appearance: none;
  border-radius: 10000000px;
  box-sizing: border-box;
  text-align: left;
  text-align: center;
  text-decoration: none;
  background-image: none !important;
}
.button:hover,
.button:focus {
  background-color: #F9B232;
}
@media (max-width: 1197px) {
  .button {
    font-size: 14px;
    line-height: 1.14285714;
    padding: 9px 20px;
  }
}
.section--smallMood .subcontent--1 {
  width: 260px;
}
.section--smallMood .subcontent--2 {
  float: right;
  width: calc(100% - 300px);
}
.area--one {
  width: 100%;
}
.area--one > .unit {
  margin-right: 2.82485876%;
  margin-left: 2.82485876%;
  width: 94.35028249%;
}
.area--one .cb-album .body,
.area--one .head,
.area--one .foot,
.area--one .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--one > .slim {
  width: 44.35028249%;
}
.area--one > .slim .head,
.area--one > .slim .foot,
.area--one > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--one > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--one > .slim .tiny {
  width: 100%;
}
.area--two {
  width: 100%;
}
.area--two > .unit {
  margin-right: 2.14899713%;
  margin-left: 2.14899713%;
  width: 95.70200573%;
}
.area--two .cb-album .body,
.area--two .head,
.area--two .foot,
.area--two .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--two > .slim {
  width: 45.70200573%;
}
.area--two > .slim .head,
.area--two > .slim .foot,
.area--two > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--two > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--two > .slim .tiny {
  width: 100%;
}
.area--three {
  width: 100%;
}
.area--three > .unit {
  margin-right: 2.14899713%;
  margin-left: 2.14899713%;
  width: 95.70200573%;
}
.area--three .cb-album .body,
.area--three .head,
.area--three .foot,
.area--three .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--three > .slim {
  width: 29.03533906%;
}
.area--three > .slim .head,
.area--three > .slim .foot,
.area--three > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--three > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--three > .slim .tiny {
  width: 100%;
}
.area--six {
  width: 100%;
}
.area--six > .unit {
  margin-right: 2.82485876%;
  margin-left: 2.82485876%;
  width: 94.35028249%;
}
.area--six .cb-album .body,
.area--six .head,
.area--six .foot,
.area--six .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--six > .slim {
  width: 27.68361582%;
}
.area--six > .slim .head,
.area--six > .slim .foot,
.area--six > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--six > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--six > .slim .tiny {
  width: 100%;
}
.area--one {
  width: 105.98802395%;
  margin-left: -2.99401198%;
}
.area--two {
  width: 104.49101796%;
  margin-left: -2.24550898%;
}
.area--three {
  width: 104.49101796%;
  margin-left: -2.24550898%;
}
.area--six {
  width: 105.98802395%;
  margin-left: -2.99401198%;
}
.area--one .pure.wide .body {
  width: 105.98802395%;
  margin-left: -2.99401198%;
}
.area--one .pure.wide .part {
  margin-left: 2.82485876%;
  margin-right: 2.82485876%;
  width: 94.35028249%;
}
.area--one .pure.wide .part.tiny {
  width: 44.35028249%;
}
.area--one .pure.wide div.link + div.link {
  margin-left: 0;
}
.area--one .flat {
  width: 94.35028249%;
}
.area--two .seam {
  width: 45.70200573%;
}
.area--two .flat {
  width: 95.70200573%;
}
.area--three .seam,
.area--three .flat {
  width: 28.93982808%;
}
.area--four .unit.wide {
  width: 53.89221557%;
}
.area--four .unit.wide:first-child {
  width: 100%;
}
.area--four .unit.slim {
  width: 41.61676647%;
}
.area--five .unit {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  aspect-ratio: 2.70384615;
}
.area--five .unit .body {
  padding-top: calc(60px - var(--spacePart));
  padding-bottom: calc(60px - var(--spacePart));
}
.area--five .unit .pict.tall:first-child {
  position: absolute;
  top: 0;
  z-index: 2;
  height: 100%;
  width: 50%;
  margin: 0;
}
.area--five .unit .pict.tall:first-child * {
  height: 100%;
}
.area--five .unit .pict.tall:first-child img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.area--five .unit.wide {
  padding-left: calc(50% + 40px);
}
.area--five .unit.wide .pict.tall:first-child {
  left: 0;
}
.area--five .unit.slim {
  padding-right: calc(50% + 40px);
}
.area--five .unit.slim .pict.tall:first-child {
  right: 0;
}
.area--six .pure.slim {
  width: 94.35028249%;
  max-width: 840px;
}
.area--six .seam {
  width: 27.68361582%;
}
.unit--hasFilter .part {
  width: calc(100% - 372px) !important;
}
.unit--hasFilter .part.code {
  float: right;
  width: 332px !important;
}
@media (max-width: 1197px) {
  .unit--hasFilter .part {
    width: calc(100% - 290px) !important;
  }
  .unit--hasFilter .part.code {
    width: 260px !important;
  }
}
/*# sourceMappingURL=./screen-medium.css.map */